import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/layout'
import Banner from '../components/banner'
import Seo from '../components/seo'
import Sidebar from '../components/sidebar'

import NewsSeoImage from '../assets/images/news.jpg'

class News extends React.Component {
    render() {
        return (
            <StaticQuery
            query={graphql`
                query NewsMeta {
                    site {
                        siteMetadata {
                            title
                            description
                            emailUrl
                            phone
                            google {
                                googleMapAPIKey
                                googleReCaptchaKey
                            }
                            social {
                                facebook {
                                    url
                                }
                                instagramUrl
                                linkedInUrl
                            }
                        }
                    }
                }
            `}
            render={data => (
                <>
                    <Layout page='news'>
                        <Seo title={`News | ${data.site.siteMetadata.title}`} description={data.site.siteMetadata.description} image={NewsSeoImage} keywords={[``]} />
                        <Banner title='News' subTitle='' titleColor='#f58220' subTitleColor='#f58220' image={NewsSeoImage} />
                        <section id='news'>
                            <Container>
                                <Row>
                                    <Col xs='12' sm='8' md='8' lg='8'>
                                        <h3>Our Latest Project News</h3>
                                        <div className='spacer20'></div>
                                        <ul>
                                            <li>Medical use approved by Edison Planning Board.</li>
                                            <li>Shopping center approval by Florham Park Planning Board.</li>
                                            <li>Multi-family subdivision approval by Morristown Planning Board.</li>
                                            <li>Carriage house approval by Harding Twp. Zoning Board.</li>
                                            <li>Service station approved by Town of Boonton Zoning Board.</li>
                                            <li>11-lot subdivision approved by Boonton Township Planning Board.</li>
                                            <li>Route 23 shopping center approval by Pequannock Planning Board.</li>
                                            <li>The Office restaurant Planning Board approval in East Hanover.</li>
                                            <li>Stirling Tavern restaurant approval in Morristown.</li>
                                            <li>Multi-family approval in Morristown.</li>
                                            <li>Industrial approvals by Newark Zoning Board.</li>
                                            <li>Shopping center approved in Evesham Township.</li>
                                            <li>Restaurant approved by the Mendham Borough Zoning Board.</li>
                                            <li>Medical use approved by New Brunswick Zoning Board.</li>
                                            <li>Industrial use approved by the Hanover Twp Planning Board.</li>
                                            <li>Restaurant approved by the Morristown Planning Board.</li>
                                            <li>Medical use approved by the North Plainfield Zoning Board of Adjustment.</li>
                                            <li>Major Site Plan approval granted by the Denville Planning Board.</li>
                                            <li>Townhouse project approved by the Morristown Zoning Board.</li>
                                            <li>Solar field approved by the Linden Planning Board.</li>
                                            <li>Calli Law is now providing legal services to clients in Pennsylvania.</li>
                                            <li>129 units approved by the Hanover Zoning Board of Adjustment.</li>
                                            <li>Morris Twp Planning Board approved subdivision.</li>
                                            <li>Morristown Planning Board: site plan approval for Silverman Group.</li>
                                            <li>Elizabeth Zoning Board: approval for DaVita dialysis clinic.</li>
                                            <li>Parsippany Planning Board: site plan approval for IBEW.</li>
                                            <li>Morristown Zoning Board: approvals for multi-family developments on both Washington Street and MLK Street.</li>
                                            <li>Bernards Twp. Zoning Board: approval for Silverman Group.</li>
                                            <li>Hanover Twp. Planning Board: variance and  subdivision approval for 34 Sherman LLC.</li>
                                            <li>South Plainfield Zoning Board: use variance and site plan approval for Carvana's new delivery hub.</li>
                                            <li>Morristown Zoning Board: approval of multi-family development for Lia Construction Inc.</li>
                                            <li>City of Burlington Land Use Board: approval of multi-family addition to 210 unit residential complex for Aion Partners.</li>
                                            <li>Sayreville Zoning Board: use variance and site plan approval for DaVita Kidney Care.</li>
                                            <li>Newark Zoning Board: use variance and site plan approval for Buckeye Pipeline LP.</li>
                                            <li>Paramus Planning Board: site plan and variance relief for DaVita Kidney Care.</li>
                                            <li>East Brunswick Planning Board: subdivision approval.</li>
                                            <li>Livingston Planning Board: site plan approval and variance relief for Summit Medical Group.</li>
                                            <li>Mountain Lakes Zoning Board: variance relief for PNC Developers.</li>
                                            <li>Mount Olive Planning Board:   site plan approval for Aion Partners - Overlook at Flanders apartment complex.</li>
                                            <li>Wyckoff Planning Board: site plan approval for T.D. Bank in Wyckoff.</li>
                                        </ul>
                                        {/* <Tabs defaultActiveKey='2019' id='projects'>
                                            <Tab eventKey='2019' title='2019'>
                                                <ul>
                                                    <li>Medical use approved by Edison Planning Board.</li>
                                                    <li>Shopping center approval by Florham Park Planning Board.</li>
                                                    <li>Multi-family subdivision approval by Morristown Planning Board.</li>
                                                    <li>Carriage house approval by Harding Twp. Zoning Board.</li>
                                                    <li>Service station approved by Town of Boonton Zoning Board.</li>
                                                    <li>11-lot subdivision approved by Boonton Township Planning Board.</li>
                                                    <li>Route 23 shopping center approval by Pequannock Planning Board.</li>
                                                    <li>The Office restaurant Planning Board approval in East Hanover.</li>
                                                    <li>Stirling Tavern restaurant approval in Morristown.</li>
                                                    <li>Multi-family approval in Morristown.</li>
                                                    <li>Industrial approvals by Newark Zoning Board.</li>
                                                    <li>Shopping center approved in Evesham Township.</li>
                                                    <li>Restaurant approved by the Mendham Borough Zoning Board.</li>
                                                    <li>Medical use approved by New Brunswick Zoning Board.</li>
                                                    <li>Industrial use approved by the Hanover Twp Planning Board.</li>
                                                    <li>Restaurant approved by the Morristown Planning Board.</li>
                                                    <li>Medical use approved by the North Plainfield Zoning Board of Adjustment.</li>
                                                    <li>Major Site Plan approval granted by the Denville Planning Board.</li>
                                                    <li>Townhouse project approved by the Morristown Zoning Board.</li>
                                                    <li>Solar field approved by the Linden Planning Board.</li>
                                                    <li>Calli Law is now providing legal services to clients in Pennsylvania.</li>
                                                    <li>129 units approved by the Hanover Zoning Board of Adjustment.</li>
                                                    <li>Morris Twp Planning Board approved subdivision.</li>
                                                </ul>
                                            </Tab>
                                            <Tab eventKey='2018' title='2018'>
                                                <ul>
                                                    <li>Morristown Planning Board: site plan approval for Silverman Group.</li>
                                                    <li>Elizabeth Zoning Board: approval for DaVita dialysis clinic.</li>
                                                    <li>Parsippany Planning Board: site plan approval for IBEW.</li>
                                                    <li>Morristown Zoning Board: approvals for multi-family developments on both Washington Street and MLK Street.</li>
                                                    <li>Bernards Twp. Zoning Board: approval for Silverman Group.</li>
                                                    <li>Hanover Twp. Planning Board: variance and  subdivision approval for 34 Sherman LLC.</li>
                                                    <li>South Plainfield Zoning Board: use variance and site plan approval for Carvana's new delivery hub.</li>
                                                    <li>Morristown Zoning Board: approval of multi-family development for Lia Construction Inc.</li>
                                                    <li>City of Burlington Land Use Board: approval of multi-family addition to 210 unit residential complex for Aion Partners.</li>
                                                    <li>Sayreville Zoning Board: use variance and site plan approval for DaVita Kidney Care.</li>
                                                    <li>Newark Zoning Board: use variance and site plan approval for Buckeye Pipeline LP.</li>
                                                    <li>Paramus Planning Board: site plan and variance relief for DaVita Kidney Care.</li>
                                                    <li>East Brunswick Planning Board: subdivision approval.</li>
                                                    <li>Livingston Planning Board: site plan approval and variance relief for Summit Medical Group.</li>
                                                    <li>Mountain Lakes Zoning Board: variance relief for PNC Developers.</li>
                                                    <li>Mount Olive Planning Board:   site plan approval for Aion Partners - Overlook at Flanders apartment complex.</li>
                                                    <li>Wyckoff Planning Board: site plan approval for T.D. Bank in Wyckoff.</li>
                                                </ul>
                                            </Tab>
                                            <Tab eventKey='2017' title='2017'>
                                                <ul>
                                                    <li>December
                                                        <ul>
                                                            <li>Larry Calli, Esq. speaking engagement at the 2017 League of Municipalities conference on Conditional Use Variances.</li>
                                                            <li>Simone Calli, Esq. appointed Governor of Architectural & Variance Committee of Smoke Rise Club, Inc.</li>
                                                        </ul>
                                                    </li>
                                                    <li>November
                                                        <ul>
                                                            <li>Calli Law obtained Zoning Board approval for a new Volkswagon dealership in Hampton Township (Sussex County).</li>
                                                            <li>Calli Law also obtained Zoning Board approval for the expansion of a 60-acre Assisted Living Campus in Franklin Township.</li>
                                                            <li>New Brunswick Planning Board approval for Gardens at Raritan apartment complex.</li>
                                                        </ul>
                                                    </li>
                                                    <li>October
                                                        <ul>
                                                            <li>New office suites coming to Pine Street in Morristown! Site plan, d(1) use variance relief and parking variance relief from the Morristown Zoning Board of Adjustment for new office suites.</li>
                                                            <li>A new Subaru dealership is coming to Hampton Township (Sussex County). Site plan approval and bulk variance relief was obtained from the Hampton Township Planning Board.</li>
                                                            <li>Successful prerogative writ trial in Superior Court defending a Morristown land development approval.</li>
                                                        </ul>
                                                    </li>
                                                    <li>August
                                                        <ul>
                                                            <li>Redevelopment Plan approved for 5-story mixed-use (120-unit apartment, commercial and restaurant space) project  in the City of Plainfield.</li>
                                                            <li>Zoning Board approval obtained to bring a new FedEx Freight distribution facility to Rockaway Township.</li>
                                                            <li>Star Lo Electric expansion project approved by the Hanover Planning Board.</li>
                                                            <li>DaVita site plan approval by the Old Bridge Planning Board.</li>
                                                        </ul>
                                                    </li>
                                                    <li>May
                                                        <ul>
                                                            <li>Morristown Zoning Board approval for an expanded parking field at the Mayo Performing Arts Center.</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </Tab>
                                        </Tabs> */}
                                    </Col>
                                    <Sidebar title={data.site.siteMetadata.title} contact={data.site.siteMetadata.contact} phone={data.site.siteMetadata.phone} email={data.site.siteMetadata.emailUrl} />
                                </Row>
                            </Container>
                        </section>
                    </Layout>
                </>
            )}
            />
        )
    }
}

export default News